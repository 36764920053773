<script setup lang="ts">
import type { AsfInputProps } from '@ui/types'
import { useField } from 'vee-validate'

const props = withDefaults(defineProps<AsfInputProps>(), {
  showLabel: true,
  maxlength: 255,
  value: '',
  inputMode: undefined
})
const isPasswordVisible = ref(false)
const fieldId = computed(() => `${props.type}-${props.id || props.name}`)
const fieldType = computed(() => (isPasswordVisible.value ? 'text' : props.type || 'text'))
const fieldName = computed(() => props.name || fieldId.value)
const isPasswordType = ref(props.type === 'password')
const rules = getValidationRules(props)
const fieldKey = computed(() => props.name)

const {
  value: reactiveValue,
  errorMessage,
  setValue,
  handleBlur,
  resetField
} = useField(() => fieldName.value, rules.rules, {
  initialValue: props.value,
  validateOnValueUpdate: false,
  label: computed(() => props.label)
})
const emit = defineEmits(['update:value', 'blur'])

watch(
  () => props.value,
  (newVal) => {
    if (newVal !== reactiveValue.value) {
      setValue(newVal)
    }
  }
)

watch(reactiveValue, () => {
  if (props.liveValueReplacer && reactiveValue.value) {
    reactiveValue.value = props.liveValueReplacer(String(reactiveValue.value))
  }
  emit('update:value', reactiveValue.value)
})

const blur = (event: Event) => {
  handleBlur(event, true)
  emit('blur', event)
}

defineExpose({
  fieldKey,
  resetField
})
</script>

<template>
  <div
    class="asf-input"
    :class="{
      'is-readonly': readonly,
      'is-disabled': disabled,
      'is-required': required,
      'is-invalid': !!errorMessage
    }"
  >
    <label
      v-if="type !== 'hidden'"
      v-e2e="`input-label`"
      :for="fieldId"
      class="asf-input__label"
      :class="{ 'sr-only': !showLabel }"
    >
      <slot name="content-label">{{ label }}</slot>
    </label>
    <div class="asf-input__inner">
      <div class="asf-input__wrapper">
        <input
          :id="fieldId"
          v-model="reactiveValue"
          v-e2e="`${name}input-field`"
          class="asf-input__field"
          :inputmode="inputMode"
          :class="{ 'asf-input__field--password': isPasswordType }"
          :type="fieldType"
          :name="fieldName"
          @blur="blur"
          :autocomplete="autocomplete"
          :maxlength="maxlength"
          :minlength="minlength"
          :placeholder="placeholder"
          :required="required"
          :readonly="readonly"
          :disabled="disabled"
          :aria-invalid="!!errorMessage"
          :aria-label="label"
          :aria-disabled="disabled"
          :aria-errormessage="errorMessage"
        />
        <slot v-if="isPasswordType" name="password-field-inner">
          <button
            v-if="reactiveValue"
            class="asf-input__password-btn"
            @click.prevent="isPasswordVisible = !isPasswordVisible"
          >
            {{ isPasswordVisible ? $t('global.hide') : $t('global.show') }}
          </button>
        </slot>
        <div v-if="errorMessage" class="asf-input__error" v-e2e="`${name}input-error`" role="alert">
          <transition name="asf-fade">
            <span v-if="errorMessage" :aria-label="`${label} ${errorMessage}`">{{ errorMessage }}</span>
          </transition>
        </div>
      </div>
      <div class="asf-input__caption" v-if="caption && !enablePasswordStrength" v-e2e="`${name}input-help-text`">
        <slot name="content-caption-message" v-bind="{ caption }">
          <span>{{ caption }}</span>
        </slot>
      </div>
      <AsfPasswordStrength
        v-if="isPasswordType && enablePasswordStrength"
        :password="reactiveValue.toString()"
        :caption="caption ? caption : ''"
      />
    </div>
  </div>
</template>
<style lang="postcss">
@import '@components/atoms/Input/Input.css';
</style>
